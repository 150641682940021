
import React from 'react';
import { Spinner } from "@fluentui/react-components"

import useApi from '../hooks/useApi';
import SubscriptionCard, { Subscription } from 'components/SubscriptionCard';
import Page from 'components/Page';


export default function ActiveSubscriptions() {
  const api = useApi()
  const [loading, setLoading] = React.useState(true)
  const [subscriptions, setSubscriptions] = React.useState<Subscription[]>([])

  React.useEffect(() => {
    getSubscriptions()
  }, [])

  async function getSubscriptions() {
    setLoading(true)
    try {
      const res = await api.get("getActiveSubscriptions")
      if (typeof res.data?.data === typeof subscriptions) {
        setSubscriptions(res.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)

    }
  }

  return (
    <Page title={`Active subscriptions:`}>
      {
        loading ?
          <div> <Spinner /> </div> :
          <div>
            {subscriptions.map(x => (<SubscriptionCard key={x.id} subscription={x} />))}
          </div>
      }
    </Page>
  );
};