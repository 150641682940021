import { useNavigate } from 'react-router-dom';
import { useInitData } from '@tma.js/sdk-react';

import Page from 'components/Page';
import HomeMenu, { HomeMenuProps } from 'components/HomeMenu';
import { Button } from '@fluentui/react-components';
import { DeleteRegular, LinkDismissRegular } from '@fluentui/react-icons';
import useApi from 'hooks/useApi';
import { useAtom } from 'jotai';
import { accountIsLinkedAtom, meAtom } from 'atoms';
import React from 'react';
import { useConfirm } from 'components/ConfirmProvider';
import ConfirmDialog from "components/ConfirmDialog";
import { User } from 'types';

export default function Home() {
  const navigate = useNavigate()
  const initData = useInitData()
  const api = useApi()
  const [me, setMe] = useAtom<User | null | undefined>(meAtom)
  const [accountIsLinked, setAccountIsLinked] = useAtom<boolean | null>(accountIsLinkedAtom)
  const [loading, setLoading] = React.useState(false)
  const confirm = useConfirm(ConfirmDialog);

  async function postUnLinkAccount() {
    confirm({
      title: "Please confirm",
      message: "Are you sure you want to unlink your ID card?",
      confirmButtonText: "Unlink",
      buttonProps: {
        style: {
          backgroundColor: "#d13438",
        },
        icon: <LinkDismissRegular />
      },
      onConfirm: async () => {
        setLoading(true)
        try {
          const res = await api.post("unLinkAccount")
          if (res.data) {
            setAccountIsLinked(null)
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)

        }
      },
    });
  }

  const menu: HomeMenuProps[] = [
    {
      title: "Active subscriptions",
      subTitle: "",
      description: "Your active subscriptions",
      onClick: () => {
        navigate("/activeSubscriptions")
      },
    },
    {
      title: "Expired subscriptions",
      subTitle: "",
      description: "Your expired subscriptions",
      onClick: () => {
        navigate("/expiredSubscriptions")
      },
    }
  ]
  return (
    <Page title={`👋 Hi ${me?.fullName} - @${initData?.user?.username}`}>
      {menu.map((x, index) => (
        <HomeMenu
          {...x}
        />
      ))}
    </Page>
  );
};