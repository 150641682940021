import React from "react";
import {
  useId,
  Link,
  Toaster,
  useToastController,
  Toast,
  ToastTitle,
  ToastBody,
  ToastTrigger,
} from "@fluentui/react-components";

type ToastProps = {
  intent?: "success" | "info" | "error" | "warning" | undefined;
  message: string;
};

const Ctx = React.createContext({
  show: (toastProps: ToastProps) => {},
});

type ToastProviderProps = {
  children: React.ReactNode;
};

export function ToastProvider({ children }: ToastProviderProps) {
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [timeout, setDismissTimeout] = React.useState(5000);

  const show = (toast: ToastProps) => {
    if (typeof toast.message === "string") {
      dispatchToast(
        <Toast>
          <ToastTitle
            action={
              <ToastTrigger>
                <Link>Dismiss</Link>
              </ToastTrigger>
            }
          >
            {toast.intent}
          </ToastTitle>
          <ToastBody>{toast.message.substring(0, 300)}</ToastBody>
        </Toast>,
        { timeout, intent: toast?.intent }
      );
    }
  };

  return (
    <Ctx.Provider value={{ show }}>
      {children}
      <Toaster toasterId={toasterId} limit={3} />
    </Ctx.Provider>
  );
}

export const useToast = () => React.useContext(Ctx);
