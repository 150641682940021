
export type User = {
    id: string;
    userName: string;
    fullName: string;
    email: string;
    adresse: string;
    passwordReset: boolean;
    gender: Gender;
    birthDay: Date;
    emailConfirmed: boolean;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    lockoutEnd: Date;
    lockoutEnabled: boolean;
    tokenExpired: boolean;
    accessFailedCount: number;
    lastConnected: Date;
    coupon: string | null
};

export enum Gender {
    M,
    F
}