import {
  createNavigator,
  useBackButtonIntegration,
  useNavigatorIntegration,
} from '@tma.js/react-router-integration';
import { useBackButton, useViewport, useMiniApp, useMainButton } from '@tma.js/sdk-react';
import { type FC, useMemo } from 'react';
import {
  Navigate,
  Route,
  Router,
  Routes,
} from 'react-router-dom';

import { routes } from './Routes';
import useApi from './hooks/useApi';
import React from 'react';
import { LinkAccount } from './views/LinkAccount';
import { useAtom } from 'jotai';
import { accountIsLinkedAtom, meAtom } from './atoms';
import logoImg from "assets/logo.png"
import { Button, Spinner } from '@fluentui/react-components';
import { ArrowClockwiseRegular } from '@fluentui/react-icons';
import ConfirmProvider from 'components/ConfirmProvider';
import { ToastProvider } from "hooks/useToast";
import { User } from 'types';

export const App: FC = () => {
  const tmaNavigator = useMemo(createNavigator, []);
  const [location, navigator] = useNavigatorIntegration(tmaNavigator);
  const backButton = useBackButton();
  const viewport = useViewport()
  const miniApp = useMiniApp()
  const mainButton = useMainButton()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [me, setMe] = useAtom<User | null | undefined>(meAtom)
  const [accountIsLinked, setAccountIsLinked] = useAtom<boolean | null>(
    accountIsLinkedAtom
  );
  const api = useApi()

  useBackButtonIntegration(tmaNavigator, backButton);

  React.useEffect(() => {
    viewport.expand()
    miniApp.setHeaderColor('#081229');
    mainButton.hide();
    mainButton.disable();
    getMe()
  }, [])

  async function getMe() {
    setLoading(true)
    try {
      const res = await api.get("me")
      if (typeof res.data?.data === typeof me) {
        setMe(res.data.data)
      } else {
        setMe(null)
      }
      setLoading(false)
    } catch (error) {
      setMe(undefined)
      setLoading(false)
    }
  }

  if (loading) {
    return (<div style={{
      height: "calc(100vh - 50px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <img alt="logo" style={{ height: 50, marginBottom: 20 }} src={logoImg} />
      <Spinner />
    </div>)
  }

  if (me === undefined) {
    return (<div style={{
      height: "calc(100vh - 50px)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <img alt='logo' style={{ height: 50, marginBottom: 20 }} src={logoImg} />
      <Button icon={<ArrowClockwiseRegular />} onClick={() => { getMe() }}>
        Try again
      </Button>
    </div>)
  }

  if (me == null) {
    return (
      <ConfirmProvider>
        <ToastProvider>
          <LinkAccount />
        </ToastProvider>
      </ConfirmProvider>
    )
  }

  return (
    <ConfirmProvider>
      <ToastProvider>
        <Router location={location} navigator={navigator}>
          <Routes>
            {routes.map((route) => <Route key={route.path} {...route} />)}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </ToastProvider>
    </ConfirmProvider>
  );
};