import moment from "moment";
import { useMiniApp, useInitData } from "@tma.js/sdk-react";
import {
  MoreHorizontal20Regular,
  QrCode24Filled,
  ServiceBellFilled,
} from "@fluentui/react-icons";
import logoImg from "../assets/logo.png";
import React from "react";
import {
  Button,
  Caption1,
  Card,
  CardHeader,
  Text,
  Divider,
  Spinner,
} from "@fluentui/react-components";

import useApi from "../hooks/useApi";
import { useAtom } from "jotai";
import { accountIsLinkedAtom } from "../atoms";
import { Subscription } from "./SubscriptionCard";

export type UserSession = {
  id: string;
  dateIn: string | null;
};

export type UserSessionProps = {
  userSession: UserSession;
};

export default function UserSessionCard({ userSession }: UserSessionProps) {
  return (
    <Card className="my-2">
      <CardHeader
        header={<div className="font-bold text-lg">{"Date of entry"}</div>}
        description={
          <div className="text-base">
            {moment.utc(userSession.dateIn).format("DD/MM/YYYY HH:mm")}
          </div>
        }
      />
    </Card>
  );
}
