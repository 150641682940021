import React, { type FC } from "react";
import { useAtom } from "jotai";
import { useQRScanner, useMiniApp } from "@tma.js/sdk-react";
import { Button, Divider, Field, Input, Spinner } from "@fluentui/react-components";
import { QrCode24Filled } from "@fluentui/react-icons";

import logoImg from "assets/logo.png";
import useApi from "hooks/useApi";
import { meAtom } from "atoms";
import { User } from "types";

export const LinkAccount: FC = () => {
  const qrScanner = useQRScanner();
  const [id, setId] = React.useState("");
  // const [accountIsLinked, setAccountIsLinked] = useAtom<boolean | null>(
  //   accountIsLinkedAtom
  // );
  const [loading, setLoading] = React.useState<boolean>(false)

  const [me, setMe] = useAtom<User | null | undefined>(meAtom)
  const [friendCoupon, setFriendCoupon] = React.useState("")

  const api = useApi();

  React.useEffect(() => {
    if (id) {
      postLinkAccount(id);
    }
  }, [id]);

  async function postLinkAccount(cardId: string) {
    try {
      setLoading(true)
      const res = await api.post("linkAccount", {
        friendCoupon,
        cardId
      });
      if (res.data) {
        document.location.reload()
      }
    } catch (error) {
      setLoading(false)
    }
  }

  if (loading) {
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
      }}
    >
      <Spinner />
    </div>;
  }

  return (
    <div
      className="flex flex-1 flex-col p-5 h-screen"
      style={{ paddingTop: 60, paddingBottom: 60 }}
    >
      <div
        className="flex flex-1 justify-center items-center cursor-pointer"
        onClick={() => { }}
      >
        <img alt="logo" style={{ height: 64 }} src={logoImg} />
      </div>

      <div className="flex flex-col flex-3">
        <div className="font-bold text-2xl text-center mt-2">
          Your account is not linked
        </div>
        <div className="font-bold text-base text-center mt-3 mb-2">
          Please scan your ID card to link your account.
        </div>
        <Button
          shape="rounded"
          appearance="primary"
          disabled={loading}
          icon={<QrCode24Filled />}
          onClick={() => {
            qrScanner.open("Scan ID card").then((content) => {
              if (typeof content === "string") {
                setId(content);
              } else {
              }
              qrScanner.close();
            });
          }}
        >
          Scan ID card
        </Button>
        <div className="mt-2">
          <Field
            label="Enter coupon registration"
          >
            <Input
              value={friendCoupon}
              disabled={loading}
              onChange={(e, data) => { setFriendCoupon(data.value) }}
              style={{

              }}
            />
          </Field>
        </div>
      </div>
    </div>
  );
};
