import moment from "moment";
import { useMiniApp, useInitData } from "@tma.js/sdk-react";
import {
  MoreHorizontal20Regular,
  QrCode24Filled,
  ServiceBellFilled,
} from "@fluentui/react-icons";
import logoImg from "../assets/logo.png";
import React from "react";
import {
  Button,
  Caption1,
  Card,
  CardHeader,
  Text,
  Divider,
  Spinner,
} from "@fluentui/react-components";

import useApi from "../hooks/useApi";
import { useAtom } from "jotai";
import { accountIsLinkedAtom } from "../atoms";

export type HomeMenuProps = {
  title: string;
  subTitle: string;
  description: string;
  onClick: () => void;
};

export default function HomeMenu({
  title,
  subTitle,
  description,
  onClick,
}: HomeMenuProps) {
  return (
    <Card className="my-2" onClick={onClick}>
      <CardHeader
        header={<div className="font-bold text-lg">{title}</div>}
        description={<div className="font-bold text-base">{subTitle}</div>}
      />
      <p>
        <b>{description}</b>
      </p>
    </Card>
  );
}
