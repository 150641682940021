import { useNavigate } from 'react-router-dom';
import { useInitData } from '@tma.js/sdk-react';
import z from "zod"
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import confetti from 'canvas-confetti';

import {
  Field,
  Input,
  Option,
  Spinner,
  Button,
  Textarea,
  Dropdown,
  Tooltip,
  Divider,
  AccordionItem,
  Accordion,
  AccordionPanel,
  AccordionHeader
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { DayOfWeek } from "@fluentui/react-calendar-compat";

import Page from 'components/Page';
import HomeMenu, { HomeMenuProps } from 'components/HomeMenu';
import { Backspace24Filled, DeleteRegular, GiftRegular, LinkDismissRegular, RocketRegular, Save24Regular, ShareRegular } from '@fluentui/react-icons';
import useApi from 'hooks/useApi';
import { useAtom } from 'jotai';
import { accountIsLinkedAtom, meAtom } from 'atoms';
import React from 'react';
import { useConfirm } from 'components/ConfirmProvider';
import ConfirmDialog from "components/ConfirmDialog";

import { Gender, User } from 'types';
import moment from 'moment';
import { useToast } from 'hooks/useToast';
import giftImg from "assets/gift.png"

const requestBody = z.object({
  email: z.string().email().or(z.literal("")),
  fullName: z.string().min(3),
  adresse: z.string(),
  gender: z.nativeEnum(Gender).nullable(),
  birthDay: z.date().nullable(),
  phoneNumber: z.string(),
});
type RequestBody = z.infer<typeof requestBody>;

export default function Home() {
  const navigate = useNavigate()
  const initData = useInitData()
  const api = useApi()
  const toast = useToast()
  const [me, setMe] = useAtom<User | null | undefined>(meAtom)

  const [loading, setLoading] = React.useState(false)
  const confirm = useConfirm(ConfirmDialog);

  const requestForm = useForm<RequestBody>({
    resolver: zodResolver(requestBody),
    defaultValues: {},
  });
  requestForm.watch();

  async function getMe() {
    setLoading(true)
    try {
      const res = await api.get("me")
      if (typeof res.data?.data === typeof me) {
        setMe(res.data.data)
      } else {
        setMe(undefined)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (me)
      requestForm.reset({
        ...me,
        birthDay: me.birthDay ? moment(me.birthDay).toDate() : null,
        adresse: me.adresse || "",
        phoneNumber: me.phoneNumber || "",
        gender: me.gender,
        email: me.email || "",
      });
  }, [me]);

  async function onSubmitRequest(data: RequestBody) {
    if (me) {
      setLoading(true);
      try {
        const response = await api.post(`/me`, {
          ...data,
          birthDay: data?.birthDay ? moment(data.birthDay).toISOString() : null,
        });
        if (typeof response.data?.message === "string") {
          toast.show({
            intent: "success",
            message: response.data?.message,
          });
          setLoading(false);
          getMe();
        }
      } catch (err: any) {
        setLoading(false);
      }
    }
  }

  async function postUnLinkAccount() {
    confirm({
      title: "Please confirm",
      message: "Are you sure you want to unlink your account from this Telegram Bot?",
      confirmButtonText: "Unlink",
      buttonProps: {
        style: {
          backgroundColor: "#d13438",
        },
        icon: <LinkDismissRegular />
      },
      onConfirm: async () => {
        setLoading(true)
        try {
          const res = await api.post("unLinkAccount")
          if (res.data) {
            setMe(null)
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)

        }
      },
    });
  }

  return (
    <Page title={`Me`}>

      <div className='flex flex-col'>
        <div className="flex justify-center items-center mt-3 cursor-pointer" onClick={() => {
          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
          });
        }}>
          <img alt='Gift' style={{ height: 120 }} src={giftImg} />
        </div>

        <p className='text-base text-center mt-3'>
          <b className='text-red-400'>Great news!</b> Share this <b>coupon</b> with your friends, and when they use it to subscribe to our Telegram Bot and make a purchase, you'll receive a gift of <b>one free session</b> for either the <b>gym</b> or the <b>pool</b>! Spread the word and enjoy your rewards!
        </p>
        <Button
          as="a"
          style={{ marginTop: 10 }}
          appearance='primary'
          disabled={loading}
          onClick={() => {

          }}
          iconPosition="after"
          icon={<ShareRegular />}
          href={`https://telegram.me/share/url?url=https://t.me/AquagymStangoulaBot&text=You received invitation from a friend: ${me?.fullName}. Join Aquagym STANGOULA. Use this coupon registration: ${me?.coupon}`}
        >
          Share
        </Button>
        <div className='font-bold text-xl text-center mt-2'>
          {me?.coupon ? me?.coupon : "/"}
        </div>
      </div>

      <Divider className='my-4' />

      <div className='flex flex-col'>
        <p className='font-bold text-base'>
          Below are your account details. You can edit them.
        </p>
        <Field
          label="Username"
          validationState={"success"}
          validationMessage={""}
        >
          <Input
            value={me?.userName}
            disabled
            style={{
              minWidth: 320,
              maxWidth: 280,
              marginRight: 10,
            }}
          />
        </Field>
        <Controller
          render={({ field }) => (
            <Field
              label="Email"
              validationState={
                requestForm.formState.errors.email ? "error" : "success"
              }
              validationMessage={
                requestForm.formState.errors.email
                  ? requestForm.formState.errors.email?.message
                  : ""
              }
            >
              <Input
                {...field}
                disabled={loading}
                style={{
                  minWidth: 320,
                  maxWidth: 280,
                  marginRight: 10,
                }}
              />
            </Field>
          )}
          name="email"
          defaultValue=""
          control={requestForm.control}
        />
        <Controller
          render={({ field }) => (
            <Field
              label="Full name"
              validationState={
                requestForm.formState.errors.fullName
                  ? "error"
                  : "success"
              }
              validationMessage={
                requestForm.formState.errors.fullName
                  ? requestForm.formState.errors.fullName?.message
                  : ""
              }
            >
              <Input
                {...field}
                disabled={loading}
                style={{
                  minWidth: 320,
                  maxWidth: 280,
                  marginRight: 10,
                }}
              />
            </Field>
          )}
          name="fullName"
          defaultValue=""
          control={requestForm.control}
        />
        <Controller
          render={({ field }) => (
            <Field
              label="Phone number"
              validationState={
                requestForm.formState.errors.phoneNumber
                  ? "error"
                  : "success"
              }
              validationMessage={
                requestForm.formState.errors.phoneNumber
                  ? requestForm.formState.errors.phoneNumber?.message
                  : ""
              }
            >
              <Input
                {...field}
                disabled={loading}
                style={{
                  minWidth: 320,
                  maxWidth: 280,
                  marginRight: 10,
                }}
              />
            </Field>
          )}
          name="phoneNumber"
          defaultValue=""
          control={requestForm.control}
        />
        <Controller
          render={({ field }) => (
            <Field
              style={{
                minWidth: 320,
                maxWidth: 280,
                marginRight: 10,
              }}
              label="Birth day"
              validationState={
                requestForm.formState.errors.adresse ? "error" : "success"
              }
              validationMessage={
                requestForm.formState.errors.adresse
                  ? requestForm.formState.errors.adresse?.message
                  : ""
              }
            >
              <div className="flex">
                <DatePicker
                  disabled={loading}
                  placeholder="Birth day"
                  {...field}
                  formatDate={(date) => {
                    if (date) return moment(date).format("DD/MM/YYYY");
                    else return "";
                  }}
                  onSelectDate={(date) => {
                    if (date) {
                      console.log();
                      requestForm.setValue(
                        "birthDay",
                        moment.utc(date.toLocaleString()).toDate(),
                        {
                          shouldDirty: true,
                        }
                      );
                    }
                  }}
                  firstDayOfWeek={DayOfWeek.Saturday}
                />
                <Tooltip content="Clear date" relationship="label">
                  <Button
                    disabled={loading}
                    style={{ marginLeft: 5 }}
                    onClick={() =>
                      requestForm.setValue("birthDay", null, {
                        shouldDirty: true,
                      })
                    }
                    icon={<Backspace24Filled />}
                  />
                </Tooltip>
              </div>
            </Field>
          )}
          name="birthDay"
          defaultValue={requestForm.getValues("birthDay")}
          control={requestForm.control}
        />
        <Controller
          render={({ field }) => (
            <Field
              style={{
                minWidth: 320,
                maxWidth: 280,
                marginRight: 10,
              }}
              label="Gender"
              validationState={
                requestForm.formState.errors.adresse ? "error" : "success"
              }
              validationMessage={
                requestForm.formState.errors.adresse
                  ? requestForm.formState.errors.adresse?.message
                  : ""
              }
            >
              <Dropdown
                disabled={loading}
                defaultValue={
                  me?.gender !== null
                    ? `${me?.gender?.valueOf() === Gender.M.valueOf()
                      ? "Male"
                      : "Female"
                    }`
                    : ""
                }
                defaultSelectedOptions={
                  me?.gender !== null
                    ? [
                      `${me?.gender?.valueOf() === Gender.M.valueOf()
                        ? "0"
                        : "1"
                      }`,
                    ]
                    : []
                }
                onOptionSelect={(ev, data) => {
                  const value =
                    data.optionValue === "0" ? Gender.M : Gender.F;
                  requestForm.setValue("gender", value, {
                    shouldDirty: true,
                  });
                }}
                placeholder="Select gender"
              >
                <Option text="Male" value="0">
                  Male
                </Option>
                <Option text="Female" value="1">
                  Female
                </Option>
              </Dropdown>
            </Field>
          )}
          control={requestForm.control}
          name="gender"
        />
        <Controller
          render={({ field }) => (
            <Field
              label="Address"
              validationState={
                requestForm.formState.errors.adresse ? "error" : "success"
              }
              validationMessage={
                requestForm.formState.errors.adresse
                  ? requestForm.formState.errors.adresse?.message
                  : ""
              }
            >
              <Textarea
                disabled={loading}
                {...field}
                style={{
                  minWidth: 320,
                  maxWidth: 280,
                  marginRight: 10,
                }}
              />
            </Field>
          )}
          name="adresse"
          defaultValue=""
          control={requestForm.control}
        />

        <Button
          appearance='primary'
          style={{ marginTop: 10 }}
          disabled={loading || !requestForm.formState.isDirty}
          onClick={() => requestForm.handleSubmit(onSubmitRequest)()}
          iconPosition="after"
          icon={<Save24Regular />}
        >
          Save
        </Button>
      </div>

      <Divider className='my-4' />

      <div className='flex flex-col'>
        <p className='font-bold text-base'>
          Please note! By clicking on the button below, you will unlink your account from this Telegram Bot.
        </p>
        <Button
          appearance='primary'
          style={{ backgroundColor: "#d13438", marginTop: 10 }}
          disabled={loading}
          onClick={() => {
            postUnLinkAccount()
          }}
          iconPosition="after"
          icon={<LinkDismissRegular />}
        >
          Unlink my ID card
        </Button>
      </div>

    </Page>
  );
};