import moment from "moment"
import { useMiniApp, useInitData } from '@tma.js/sdk-react';
import { MoreHorizontal20Regular, QrCode24Filled, ServiceBellFilled } from "@fluentui/react-icons";
import logoImg from "../assets/logo.png"
import React from 'react';
import { Button, Caption1, Card, CardHeader, Text, Divider, Spinner } from "@fluentui/react-components"

import useApi from '../hooks/useApi';
import { useAtom } from 'jotai';
import { accountIsLinkedAtom } from '../atoms';
import { useNavigate } from "react-router-dom";

export type Subscription = {
  id: string;
  serviceName: string | null;
  serviceDescription: string | null;
  maxSeancesNumber: number;
  endDate: string | null;
  isExpired: number;
  startDate: string | null;
  seancesNumber: number;
}

export type SubscriptionCardProps = {
  subscription: Subscription;
  disabled?: boolean
};

export default function SubscriptionCard({ subscription, disabled = false }: SubscriptionCardProps) {
  const navigate = useNavigate();
  return (
    <Card
      className="my-2"
      onClick={
        disabled ? undefined : () => {
          navigate("/subscriptionDetails/" + subscription.id)
        }
      }
    >
      <CardHeader

        header={<div className="font-bold text-lg">{subscription.serviceName}</div>}
        description={<div className="text-base">{subscription.serviceDescription}</div>}
      />
      <p>
        {subscription.isExpired === 1 ? <span style={{ color: "#d13438" }}>Expired subscription</span> : "Start of subscription"} <b>{moment.utc(subscription.startDate).format("DD/MM/YYYY HH:mm")}</b>.<br /> You have <b>({subscription.maxSeancesNumber - subscription.seancesNumber})</b> session(s) remaining out of <b>({subscription.maxSeancesNumber})</b> before <b>{moment.utc(subscription.endDate).format("DD/MM/YYYY HH:mm")}.</b>
      </p>
    </Card>
  );
};