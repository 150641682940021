import React from "react";
import { useMemo } from "react";
import axios, { AxiosError, AxiosInstance } from "axios";
import { retrieveLaunchParams } from "@tma.js/sdk";

import { apiURL } from "const";
import { useToast } from "hooks/useToast";

const { initDataRaw } = retrieveLaunchParams();

export default function useApi(): AxiosInstance {
  const [token, setToken] = React.useState(initDataRaw);
  const toast = useToast();
  return useMemo(() => {
    const api = axios.create({
      // timeout: 30000,
      baseURL: apiURL,
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });

    api.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        const msgErr: string[] = [];
        if (axios.isAxiosError(error)) {
          if (error.response && Array.isArray(error.response.data)) {
            msgErr.push(
              "\n" +
              error.response.data
                .map((msg: any) => `${msg.path}: ${msg.message}`)
                .join(", ")
            );
            // toast.show({
            //   intent: "error",
            //   message: error.response.data
            //     .map((msg: any) => `${msg.path}: ${msg.message}`)
            //     .join(", "),
            // });
          }
          if (typeof error?.response?.data?.message === "string") {
            msgErr.push("\n" + error?.response?.data?.message);
            // toast.show({
            //   intent: "error",
            //   message: error?.response?.data?.message,
            // });
          }
          if (error?.response?.data?.message === "jwt expired") {
            // TODO: implement dialog to login
            // setJwtExpired(true);
          }
          if (typeof error?.message === "string") {
            msgErr.push("\n" + error?.message);
            // toast.show({
            //   intent: "error",
            //   message: error?.message,
            // });
          }
          if (msgErr.length > 0) {
            toast.show({
              intent: "error",
              message: msgErr.toString(),
            });
          }
        } else {
          console.log(error);
          // Just a stock error
        }

        return Promise.reject(error);
      }
    );
    return api;
  }, [token]);
}
