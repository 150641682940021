import React, { type FC, useEffect } from 'react';
import ReactDOM from 'react-dom/client'
import "index.css"

import { setDebug } from '@tma.js/sdk';
import { DisplayGate, SDKProvider, useLaunchParams } from '@tma.js/sdk-react';
import { FluentProvider, teamsLightTheme } from "@fluentui/react-components";


import { App } from 'App';
import { ErrorBoundary } from 'components/ErrorBoundary';

const DisplayError: FC<{ error: unknown }> = ({ error }) => (
  <blockquote>
    <code>
      {error instanceof Error
        ? error.message
        : JSON.stringify(error)}
    </code>
  </blockquote>
);

const ErrorBoundaryError: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>An unhandled error occurred:</p>
    <DisplayError error={error} />
  </div>
);

const ErrorBoundarySDK: FC<{ error: unknown }> = ({ error }) => (
  <div>
    <p>
      An error occurred while initializing the SDK. You are probably running the application
      outside of Telegram (in usual browser, for example).
    </p>
    <DisplayError error={error} />
  </div>
);

const Loading: FC = () => <div>Application is loading</div>;

const Inner: FC = () => {
  const launchParams = useLaunchParams();

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (launchParams.startParam === 'debug') {
      setDebug(true);
      import('eruda').then((lib) => lib.default.init());
    }
  }, [launchParams]);

  return (
    <SDKProvider options={{ acceptCustomStyles: true, cssVars: true, complete: true }}>
      {/* @ts-ignore */}
      <DisplayGate error={ErrorBoundarySDK} loading={Loading} initial={Loading}>
        <FluentProvider theme={teamsLightTheme}>
          <App />
        </FluentProvider>
      </DisplayGate>
    </SDKProvider>
  );
};

export const Root: FC = () => (
  <ErrorBoundary fallback={ErrorBoundaryError}>
    <Inner />
  </ErrorBoundary>
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);