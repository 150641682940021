import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import { ConfirmProps } from "components/ConfirmProvider";

type ConfirmDialogProps = ConfirmProps & {
  title: string;
  message: NonNullable<React.ReactNode>;
  confirmButtonText?: string;
  buttonProps?: ButtonProps;
};

export default function ConfirmDialog({
  title,
  message,
  confirmButtonText,
  buttonProps,
  onConfirm,
  onCancel,
  isConfirming,
  open,
}: ConfirmDialogProps): JSX.Element {
  return (
    <Dialog open={open} onOpenChange={onCancel} >
      <DialogSurface style={{ width: "90%" }}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button
                autoFocus
                onClick={onCancel}
                disabled={isConfirming}
                appearance="secondary"
              >
                Cancel
              </Button>
            </DialogTrigger>
            <Button
              onClick={onConfirm}
              disabled={isConfirming}
              appearance="primary"
              iconPosition="after"
              {...buttonProps}
              icon={isConfirming ? <Spinner size="tiny" /> : buttonProps?.icon}
            >
              {confirmButtonText ? confirmButtonText : "Okay"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}
