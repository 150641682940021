import type { ComponentType, JSX } from "react";

import Home from "./pages/Home";
import Me from "./pages/Me";
import ActiveSubscriptions from "./pages/ActiveSubscriptions";
import SubscriptionDetails from "pages/SubscriptionDetails";
import ExpiredSubscriptions from "pages/ExpiredSubscriptions";

interface Route {
  path: string;
  Component: ComponentType;
  title?: string;
  icon?: JSX.Element;
}

export const routes: Route[] = [
  { path: "/", Component: Home },
  { path: "/me", Component: Me },
  { path: "/activeSubscriptions", Component: ActiveSubscriptions },
  { path: "/expiredSubscriptions", Component: ExpiredSubscriptions },
  { path: "/subscriptionDetails/:id", Component: SubscriptionDetails },
];
