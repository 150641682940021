import logoImg from "../assets/logo.png";
import React from "react";
import { Avatar, Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { User } from "types";
import { meAtom } from "atoms";

export type PageProps = {
  title?: string;
  subTitle?: string;
  children: React.ReactNode;
};

export default function Page({ title, subTitle, children }: PageProps) {
  const navigate = useNavigate();
  const [me, setMe] = useAtom<User | null | undefined>(meAtom)

  React.useEffect(() => {
    document.title = `${title} - Aquagym Stangoula`;
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <div className="flex flex-col">
      <div
        style={{
          backgroundColor: "#081229",
          minHeight: 55,
          maxHeight: 55,
          position: "fixed",
          top: 0,
          width: "100%",
        }}
        className="flex justify-between items-center sticky top-0 bg-white border-b border-slate-200 z-30 p-2 shadow-xl"
      >
        <div className="cursor-pointer" onClick={() => navigate("/")}>
          <img alt="logo" style={{ height: 32 }} src={logoImg} />
        </div>
        <div>
          <Button
            onClick={() => {
              navigate("/me");
            }}
            shape="circular"
            appearance="subtle"
            icon={
              <Avatar
                size={32}
                name={me?.fullName}
              />
            }
          />
        </div>
      </div>
      <div
        className="flex flex-1 flex-col"
        style={{ paddingTop: 60, overflowY: "scroll", minHeight: 1000 }}
      >
        <div className="font-bold text-sm px-2 pb-2 pt-1 mb-2 shadow-md">
          {title}
        </div>
        <div className="flex flex-1 flex-col pl-4 pr-1">{children}</div>
      </div>
    </div>
  );
}
