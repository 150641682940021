import React from "react";
import { Divider, Spinner } from "@fluentui/react-components";

import useApi from "../hooks/useApi";
import SubscriptionCard, { Subscription } from "components/SubscriptionCard";
import Page from "components/Page";
import UserSessionCard, { UserSession } from "components/UserSessionCard";
import { useParams } from "react-router-dom";
import moment from "moment";

export type SubscriptionDetailsProps = {
  // subscription: Subscription
};

export type SubscriptionDetailsType = {
  subscription: Subscription;
  userSessions: UserSession[];
};

export default function SubscriptionDetails({ }: SubscriptionDetailsProps) {
  const params = useParams();
  const api = useApi();
  const [loading, setLoading] = React.useState(true);
  const [subscriptionDetails, setSubscriptionDetails] =
    React.useState<SubscriptionDetailsType | null>(null);

  React.useEffect(() => {
    getSubscriptionDetails();
  }, []);

  async function getSubscriptionDetails() {
    if (typeof params.id === "string") {
      setLoading(true);
      try {
        const res = await api.get("getSubscriptionDetails/" + params.id);
        if (typeof res.data?.data === typeof subscriptionDetails) {
          setSubscriptionDetails(res.data.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  }

  return (
    <Page title={`Subscription details`}>
      {loading ? (
        <div>
          {" "}
          <Spinner />{" "}
        </div>
      ) : (
        <div>
          {subscriptionDetails && (
            <>
              <SubscriptionCard disabled subscription={{ ...subscriptionDetails.subscription, seancesNumber: subscriptionDetails.userSessions.length }} />
              <Divider />
              <div className="font-bold text-lg mb-2">Last sessions:</div>
              <>
                {subscriptionDetails.userSessions.length === 0 ? (
                  <div>You don't use any sessions</div>
                ) : (
                  <>
                    {subscriptionDetails.userSessions.map((x) => (
                      <UserSessionCard key={x.id} userSession={x} />
                    ))}
                  </>
                )}
              </>
            </>
          )}
        </div>
      )}
    </Page>
  );
}
